
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HourAnimation',
  components: {
  },

  mounted() {
    const second = this.$refs.second
    const minute = this.$refs.minute
    const hour = this.$refs.hour

    if (
        second instanceof HTMLElement
        && minute instanceof HTMLElement
        && hour instanceof HTMLElement
    ){

      this.setElementRotation({
        element: second,
        value: 30,
        incrementalAngleValue: 360 / 60,
      })

      this.setElementRotation({
        element: minute,
        value: 10,
        incrementalAngleValue: 360 / 60,
      })

      this.setElementRotation({
        element: hour,
        value: 10,
        incrementalAngleValue: 360 / 12,
      })

      setTimeout(() => {
        this.startAnimation({
          second,
          minute,
          hour,
        })
      }, 5_000)

    }

  },

  data() {
    return {
      frameCounter: 0,
    }
  },

  methods: {

    startAnimation({second, minute, hour}: { second: HTMLElement, minute: HTMLElement, hour: HTMLElement }) {

      const timeAnimation = 3000;

      second.style.transition = `transform ${timeAnimation}ms cubic-bezier(1, 0, .75, 1)`
      minute.style.transition = `transform ${timeAnimation}ms cubic-bezier(1, 0, .75, 1)`
      hour.style.transition   = `transform ${timeAnimation}ms cubic-bezier(1, 0, .75, 1)`

      const date = new Date()
      const localTimeZoneGetTime =
          date.getSeconds()   * 1_000
          + date.getMinutes() * 1_000 * 60
          + date.getHours()   * 1_000 * 60 * 60


      this.setElementRotation({
        element: second,
        value:                  (localTimeZoneGetTime + timeAnimation )/ 1_000 % 60,
        incrementalAngleValue:  360 / 60,
      })

      this.setElementRotation({
        element: minute,
        value:                  (localTimeZoneGetTime + timeAnimation ) / 1_000 / 60 % 60,
        incrementalAngleValue:  360 / 60,
      })

      this.setElementRotation({
        element: hour,
        value:                  ( (localTimeZoneGetTime + timeAnimation ) / 1_000 / 60 / 60 % 24 ),
        incrementalAngleValue:  360 / 12,
      })

      window.setTimeout(() => {
        second.style.transition = ""
        minute.style.transition = ""
        hour.style.transition   = ""
        this.animation({second, minute, hour})
      }, timeAnimation)


    },

    animation(args: {
      second: HTMLElement,
      minute: HTMLElement,
      hour:   HTMLElement,
    }) {

      const date = new Date()
      const localTimeZoneGetTime =
            date.getSeconds() * 1_000
          + date.getMinutes() * 1_000 * 60
          + date.getHours()   * 1_000 * 60 * 60

      // if(this.frameCounter % (60 / 5) === 0) {
        this.setElementRotation({
          element: args.second,
          value:                  date.getTime(),
          incrementalAngleValue:  360 / 60 / 1_000,
        })
      // }

      this.setElementRotation({
        element: args.minute,
        value:                  date.getTime(),
        incrementalAngleValue:  360 / 60 / 1_000 / 60,
      })

      this.setElementRotation({
        element: args.hour,
        value:                  (localTimeZoneGetTime) + 1,
        incrementalAngleValue:  360 / 60 / 1_000 / 60 / 12,
      })

      window.requestAnimationFrame(() => {
        this.frameCounter++
        this.animation({second: args.second, minute: args.minute, hour: args.hour})
      })
    },

    setElementRotation(args: {
      value: number,
      incrementalAngleValue: number,
      element: HTMLElement,
    }) {
      const newAngle = args.value * args.incrementalAngleValue
      args.element.style.transform = `rotate(${newAngle}deg)`
    }

},


});
