import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '../assets/section_main/home-section_main-top.png'
import _imports_1 from '../assets/section_main/home-section_main-bottom-right.png'
import _imports_2 from '../assets/section_main/home-section_main-bottom-left.png'


const _withScopeId = n => (_pushScopeId("data-v-4a24c553"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "v-section-main" }
const _hoisted_2 = { class: "v-section-main__gallery v-section-main__gallery--intro" }
const _hoisted_3 = { class: "v-section-main__txt" }
const _hoisted_4 = { class: "v-section-main__txt__background" }
const _hoisted_5 = { class: "v-section-main__gallery" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_hour_animation = _resolveComponent("hour-animation")!
  const _component_subsection = _resolveComponent("subsection")!
  const _component_image_gallery = _resolveComponent("image-gallery")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_hour_animation)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("img", {
          alt: "",
          ref: "top",
          style: _normalizeStyle(_ctx.style.top),
          class: "v-section-main__txt__background__top lo-section-bg--top",
          src: _imports_0
        }, null, 4),
        _createElementVNode("img", {
          alt: "",
          ref: "right",
          style: _normalizeStyle(_ctx.style.right),
          class: "v-section-main__txt__background__bottom-right lo-section-bg--right",
          src: _imports_1
        }, null, 4),
        _createElementVNode("img", {
          alt: "",
          ref: "left",
          style: _normalizeStyle(_ctx.style.left),
          class: "v-section-main__txt__background__bottom-left lo-section-bg--left",
          src: _imports_2
        }, null, 4)
      ]),
      _createVNode(_component_subsection, {
        style: {"text-align":"center"},
        styleOption: "regular",
        title: "Level One",
        arrowNav: false
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        _: 3
      })
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_ctx.imageGallery)
        ? (_openBlock(), _createBlock(_component_image_gallery, {
            key: 0,
            arrayOfImgData: _ctx.imageGallery
          }, null, 8, ["arrayOfImgData"]))
        : _createCommentVNode("", true)
    ])
  ]))
}