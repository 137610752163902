import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '../assets/intro/background.jpg'
import _imports_1 from '../assets/intro/hour.png'
import _imports_2 from '../assets/intro/minute.png'
import _imports_3 from '../assets/intro/second.png'


const _withScopeId = n => (_pushScopeId("data-v-3cc719c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "v-hour-animation" }
const _hoisted_2 = { class: "v-hour-animation__container" }
const _hoisted_3 = {
  ref: "background",
  src: _imports_0,
  alt: "background"
}
const _hoisted_4 = {
  ref: "hour",
  src: _imports_1,
  alt: "hour"
}
const _hoisted_5 = {
  ref: "minute",
  src: _imports_2,
  alt: "minute"
}
const _hoisted_6 = {
  ref: "second",
  src: _imports_3,
  alt: "second"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", _hoisted_3, null, 512),
      _createElementVNode("img", _hoisted_4, null, 512),
      _createElementVNode("img", _hoisted_5, null, 512),
      _createElementVNode("img", _hoisted_6, null, 512)
    ])
  ]))
}