import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '../assets/ui/gallery-arrow-left__active.svg'
import _imports_1 from '../assets/ui/gallery-arrow-left__unactive.svg'
import _imports_2 from '../assets/ui/gallery-arrow-right__unactive.svg'
import _imports_3 from '../assets/ui/gallery-arrow-right__active.svg'


const _hoisted_1 = { class: "v-subsection__content v-subsection__content--centred lo-remove-child-margin lo-g-gutter--half" }
const _hoisted_2 = {
  key: 0,
  class: "v-subsection__ui-box"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(["v-subsection", {
              'is-l': _ctx.styleOption === 'large',
           }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h1", {
        class: _normalizeClass(["v-subsection__content__title lo-g-gutter--half", {
            'title-variant': _ctx.titleVariant
          }])
      }, _toDisplayString(_ctx.title), 3),
      _createElementVNode("div", {
        class: "v-subsection__content__slot",
        ref: "scrollContent",
        onScroll: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onScrollInSlideContainer && _ctx.onScrollInSlideContainer(...args)))
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 544)
    ]),
    (_ctx.arrowNav)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.currentIndex > 0)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                class: "v-subsection__ui--left",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.slideInGallery('toLeft'))),
                src: _imports_0,
                alt: ""
              }))
            : (_openBlock(), _createElementBlock("img", {
                key: 1,
                class: "v-subsection__ui--left",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.slideInGallery('toLeft'))),
                src: _imports_1,
                alt: ""
              })),
          (_ctx.isLastSlide)
            ? (_openBlock(), _createElementBlock("img", {
                key: 2,
                class: "v-subsection__ui--right",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.slideInGallery('toRight'))),
                src: _imports_2,
                alt: ""
              }))
            : (_openBlock(), _createElementBlock("img", {
                key: 3,
                class: "v-subsection__ui--right",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.slideInGallery('toRight'))),
                src: _imports_3,
                alt: ""
              }))
        ]))
      : _createCommentVNode("", true)
  ], 2))
}