
import {defineComponent, PropType, VueElement} from 'vue';
import Subsection from "@/components/Subsection.vue"
import {useStore} from "vuex"
import {key} from "@/store"
import {IApiResonse_home__section} from "@/global/api"

export default defineComponent({
  name: 'SectionBio',
  components: {
    Subsection
  },

  props: {
    dataSlide: {
      require: true,
      type: Object as PropType<IApiResonse_home__section>,
    }
  },

  computed: {
  },

  mounted() {
    this.$nextTick (() => {

      if(! (this.$refs.componentElement instanceof HTMLElement) ) return
      const slides = this.$refs.componentElement.querySelectorAll('.v-section-bio__slides')

      for(const slide of slides) {
        const slideTitle          = slide.querySelector('.v-section-bio__slides__content')?.querySelector('h1')
        const slideTitleContainer = slide.querySelector('.v-section-bio__slides__subtitle') as HTMLElement

        if(slideTitle instanceof HTMLElement) {
          slideTitleContainer.innerText = slideTitle.innerText
          slideTitle.remove()
        } else {
          slideTitleContainer.remove()
        }

      }
    })

  }

});
