
import {defineComponent, PropType} from 'vue';
import {IApiImageData} from "@/global/api"

export default defineComponent({
  name: 'ImageGallery',

  components: {
  },

  data() {
    return {
      currentIndex: 0,
    }
  },

  props: {
    arrayOfImgData: {
      required: true,
      type: Object as PropType<IApiImageData[]>,
    },
  },

  methods: {

    onScrollInGallery(e: MouseEvent) {
      if( !(this.$refs.imageGallery instanceof HTMLElement) ) return

      const scrollLeft        = this.$refs.imageGallery.scrollLeft
      const imageGalleryWidth = this.$refs.imageGallery.getBoundingClientRect().width

      this.currentIndex = Math.floor( (scrollLeft + imageGalleryWidth / 2 ) / imageGalleryWidth )
    },

    slideInGallery(direction: 'toLeft' | 'toRight') {
      if( !(this.$refs.imageGallery instanceof HTMLElement) ) return

      let index = this.currentIndex

      switch (direction) {
        case "toLeft":
          index--
          if (index < 0) break
          this.$refs.imageGallery.scrollTo({
            left: index * this.$refs.imageGallery.getBoundingClientRect().width,
            behavior: 'smooth',
          })
          break
        case "toRight":
          index++
          if (index >= Object.keys( this.arrayOfImgData ).length) break
          this.$refs.imageGallery.scrollTo({
            left: index * this.$refs.imageGallery.getBoundingClientRect().width,
            behavior: 'smooth',
          })
          console.log('right')

      }
    },

  },
});
