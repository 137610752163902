
import { defineComponent } from 'vue';
import ImageGallery from "@/components/ImageGallery.vue"
import HourAnimation from "@/components/HourAnimation.vue"
import Subsection from "@/components/Subsection.vue"
import {useStore} from "vuex"
import {key} from "@/store"
import {IApiImageData, IApiResonse_home} from "@/global/api"

export default defineComponent({
  name: 'SectionMain',

  components: {
    Subsection,
    HourAnimation,
    ImageGallery
  },

  data() {
    return {
      store: useStore(key),
      style: {
        top: {},
        right: {},
        left: {},
      }
    }
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('scroll', () => {
        this.style.top    = this.computedStyle(this.$refs.top)
        this.style.right  = this.computedStyle(this.$refs.right)
        this.style.left   = this.computedStyle(this.$refs.left)
      })
    })
  },

  methods: {
    computedStyle(el: unknown): {
      opacity: number
    } {
      if(! (el instanceof HTMLElement) ) return {opacity: 1}
      return {
        opacity: 1 - el.getBoundingClientRect().top / (window.innerHeight / 2)
      }
    },
  },

  computed: {
    imageGallery(): null | IApiImageData[] {
      if (this.store.state.homeData === null || !('slider' in this.store.state.homeData)) null
      return (this.store.state.homeData as IApiResonse_home).slider
    }
  }

});
