import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '../assets/ui/counter--active.svg'
import _imports_1 from '../assets/ui/counter--unactive.svg'


const _withScopeId = n => (_pushScopeId("data-v-04e685af"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "v-image-gallery" }
const _hoisted_2 = { class: "v-image-gallery__box" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "v-image-gallery__counter" }
const _hoisted_5 = ["alt"]
const _hoisted_6 = ["alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: "v-image-gallery__ui v-image-gallery__ui--left",
        src: "/img/NC-ui-arrow_2-left.svg",
        alt: "ui arrow left navigation",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.slideInGallery('toLeft')))
      }),
      _createElementVNode("img", {
        class: "v-image-gallery__ui v-image-gallery__ui--right",
        src: "/img/NC-ui-arrow_2-right.svg",
        alt: "ui arrow right navigation",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.slideInGallery('toRight')))
      }),
      _createElementVNode("div", {
        class: "v-image-gallery__imgs",
        ref: "imageGallery",
        onScroll: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onScrollInGallery && _ctx.onScrollInGallery(...args)))
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.arrayOfImgData, (imageData) => {
          return (_openBlock(), _createElementBlock("img", {
            src: imageData.url,
            alt: ""
          }, null, 8, _hoisted_3))
        }), 256))
      ], 544)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(_ctx.arrayOfImgData).length, (imageDataIndex) => {
        return (_openBlock(), _createElementBlock(_Fragment, null, [
          (imageDataIndex-1 === _ctx.currentIndex)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                alt: imageDataIndex,
                src: _imports_0
              }, null, 8, _hoisted_5))
            : (_openBlock(), _createElementBlock("img", {
                key: 1,
                alt: imageDataIndex,
                src: _imports_1
              }, null, 8, _hoisted_6))
        ], 64))
      }), 256))
    ])
  ]))
}