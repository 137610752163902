import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2aa0fd7c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "v-home" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_section_main = _resolveComponent("section-main")!
  const _component_section_bio = _resolveComponent("section-bio")!
  const _component_section_talents = _resolveComponent("section-talents")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.intro)
      ? (_openBlock(), _createBlock(_component_section_main, {
          key: 0,
          id: "v-home__main"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: "lo-g-gutter--half",
              innerHTML: _ctx.intro.content
            }, null, 8, _hoisted_2)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (('specification' in _ctx.store.state.homeData))
      ? (_openBlock(), _createBlock(_component_section_bio, {
          key: 1,
          id: "v-home__specification",
          dataSlide: _ctx.store.state.homeData.specification
        }, null, 8, ["dataSlide"]))
      : _createCommentVNode("", true),
    (('biographie' in _ctx.store.state.homeData))
      ? (_openBlock(), _createBlock(_component_section_bio, {
          key: 2,
          id: "v-home__bio",
          dataSlide: _ctx.store.state.homeData.biographie
        }, null, 8, ["dataSlide"]))
      : _createCommentVNode("", true),
    _createVNode(_component_section_talents, { id: "v-home__talents" })
  ]))
}