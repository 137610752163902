
import { defineComponent } from 'vue';
import Subsection from "@/components/Subsection.vue"
import {useStore} from "vuex"
import {key} from "@/store"
import {IApiResonse_home__section} from "@/global/api"

export default defineComponent({
  name: 'SectionTalents',
  components: {
    Subsection
  },

  data() {
    return {
      store: useStore(key),
    }
  },

  computed: {
    talents(): IApiResonse_home__section | null {
      if(this.store.state.homeData === null) return null
      if ('talents' in this.store.state.homeData) return this.store.state.homeData.talents
      return null
    }
  }


});
