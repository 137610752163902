
import {defineComponent, PropType} from 'vue';

export default defineComponent({
  name: 'Subsection',
  components: {
  },

  mounted() {
    this.$nextTick(() => {
      if( this.$refs.scrollContent instanceof HTMLElement)
        this.slideNumber = this.$refs.scrollContent.childElementCount
    })
  },

  data() {
    return {
      currentIndex: 0,
      slideNumber: 1,
    }
  },

  props:{
    styleOption: {
      type: String as PropType<"regular" | "large">,
      require: true,
      default: 'regular'
    },

    title: {
      type: String,
      require: true,
    },

    arrowNav: {
      type: Boolean,
      require: false,
      default: true,
    },

    titleVariant: {
      type: Boolean,
      require: false,
      default: false,
    },
  },

  computed: {
    isLastSlide(): boolean {
      return this.currentIndex + 1 === this.slideNumber
    }
  },

  methods: {
    onScrollInSlideContainer(e: MouseEvent) {
      if( !(this.$refs.scrollContent instanceof HTMLElement) ) return

      const scrollLeft        = this.$refs.scrollContent.scrollLeft
      const imageGalleryWidth = this.$refs.scrollContent.getBoundingClientRect().width

      this.currentIndex = Math.floor( (scrollLeft + imageGalleryWidth / 2 ) / imageGalleryWidth )
    },

    slideInGallery(direction: 'toLeft' | 'toRight') {
      if( !(this.$refs.scrollContent instanceof HTMLElement) ) return

      let index = this.currentIndex

      switch (direction) {
        case "toLeft":
          index--
          if (index < 0) break
          this.$refs.scrollContent.scrollTo({
            left: index * this.$refs.scrollContent.getBoundingClientRect().width,
            behavior: 'smooth',
          })
          break
        case "toRight":
          index++
          if (index >= this.slideNumber) break
          this.$refs.scrollContent.scrollTo({
            left: index * this.$refs.scrollContent.getBoundingClientRect().width,
            behavior: 'smooth',
          })
      }
    },

  }

});
